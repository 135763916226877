import { Suspense, useEffect, useMemo, useState } from 'react'
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom'
import { SuspenseFallback, ToastNotification } from '../components'
import { appRoutes, publicRoutes } from './appRoutes'
import { getStorageItem, hasPlatformFeature, setStorageItem } from '../utils'
import { useQuery } from '@apollo/client'
import { GET_PARTNER_BY_HOST_NAME } from '../graphql/query/partner'
import { CURRENT_USER, DATA_FOR_SIDE_EFFECTS } from '../graphql/query/me'
import mixpanel from 'mixpanel-browser'
import axios from 'axios'
import { themePrimaryColor } from '../graphql/client'
import NotFound from '../modules/RouteNotFound'
import { DEFAULT_TRANSLATION } from '../constants/defaultTranslation';
import { useTranslation } from '../hooks/useTranslation';
import { IntlProvider } from 'react-intl'

window.isAppflows = window.location.hostname.includes('appflow')
window.isMailmunch = window.location.hostname.includes('mailmunch')

// remove params from url
const removeParams = (validToken, removeUserId, userImpersonated, agentToken) => {
  const url = new URL(window.location.href)
  if (removeUserId) url.searchParams.delete('user_id')
  if (userImpersonated && agentToken) {
    setStorageItem('user_impersonated', userImpersonated)
    setStorageItem('agent_token', agentToken)
    url.searchParams.delete('user_impersonated')
    url.searchParams.delete('agent_token')
  }
  url.searchParams.delete('user_token')

  if (!validToken) {
    window.history.replaceState({}, '', url)
  } else {
    window.location = url
  }
}

const Routes = () => {
  const [translation, setTranslation] = useState(null);
  // Get URL Search Params
  const { searchParams } = new URL(window.location.href)
  let userToken = searchParams.get('user_token')
  let userId = searchParams.get('user_id')
  let userImpersonated = searchParams.get('user_impersonated') === 'true'
  let agentToken = searchParams.get('agent_token')

  // Partner Query
  const { loading: partnerLoading, data: { getPartnerByHostName = {} } = {} } = useQuery(GET_PARTNER_BY_HOST_NAME, {
    variables: {
      hostName: window.location.hostname
    },
    onCompleted: (data) => {
      // De-structure Data
      const { getPartnerByHostName = {} } = data || {}
      const { variables, parameterizedName, data: partnerData, name, appDomain, platformFeatures, unlayerToolUrls, pagesCnameDomain, links = {}, domains, logo = {}, active } = getPartnerByHostName || {}
      const { uiTheme, columns = [], maxImporterFileSize } = variables || {}
      const { favicon: { url: faviconUrl = "" } = {} } = logo
      const { triggers = {} } = partnerData || {}
      // Set Partner Variables in Window
      window.platformFeatures = platformFeatures
      window.unlayerToolUrls = unlayerToolUrls
      window.domains = domains
      window.pagesCnameDomain = pagesCnameDomain
      window.partnerLinks = links
      window.partnerLogos = logo
      window.isAppflows = parameterizedName?.toLowerCase() === 'appflows' || window.isAppflows
      window.isMailmunch = parameterizedName?.toLowerCase() === 'mailmunch' || window.isMailmunch
      window.isAvb = parameterizedName?.toLowerCase() === 'emailiq' || window.isAvb
      window.parameterizedPartnerName = parameterizedName?.toLowerCase()
      window.partnerName = name
      window.isPartnerActive = active
      const isDevelopment = process.env.NODE_ENV === "development"
      window.appDomain = `${appDomain}${isDevelopment ? ':3000' : ''}`
      window.dynamicAppDomain = `https://#{domain}.${appDomain}${isDevelopment ? ':3000' : ''}`
      window.partnerColumns = columns
      window.partnerTriggers = triggers
      window.maxImporterFileSize = maxImporterFileSize || 100
      

      // Set Document Title, Favicon and UI Theme
      if (name) document.title = name;

      if (faviconUrl) {
        const favicon = document.getElementById('favicon')
        favicon.href = faviconUrl
      }

      if (uiTheme && typeof uiTheme === 'object' && Object.keys(uiTheme).length) {
        themePrimaryColor(uiTheme?.PRIMARY)
        for (let key in uiTheme) {
          const colorKey = key.toLowerCase().split('_').join('-');
          document.documentElement.style.setProperty(`--color-${colorKey}`, uiTheme[key])
        }
      }

      // Set Mixpanel Super Properties
      if (Object.keys(getPartnerByHostName)?.length > 0) {
        const mixpanelKey = (process.env.NODE_ENV === 'production' && parameterizedName === 'mailmunch') ? process.env.REACT_APP_MIXPANEL_TOKEN : process.env.REACT_APP_MIXPANEL_TOKEN_DEV
        mixpanel.init(mixpanelKey, {
          debug: process.env.NODE_ENV === 'development',
          loaded: function () {
            console.log('<!-----mixpanel loaded----!>')
          }
        });
        window.track_mixpanel = function (event, props) {
          if (!props) props = {};
          mixpanel?.track(event, { ...props, ...window?.eventProperties });
        }
      }

    }
  })

  // Current User Query
  const { loading: fetchingUser, data: { me: user } = {} } = useQuery(CURRENT_USER, {
    skip: !getStorageItem('user:token'),
    onCompleted: (data) => {
      const { me: user = {} } = data || {}
      if (user) {
        mixpanel.identify(user?.id)

        // Set User Variables in Window
        window.isBetaUser = user?.isBetaUser
        window.siteId = user?.site?.id

        // GTM mm_purchase events for shopify/wix
        const isWixOrShopify = ['wix', 'shopify'].includes(user?.channelName?.toLowerCase())
        if (isWixOrShopify && process.env.NODE_ENV === 'production' && (window.parameterizedPartnerName === 'mailmunch' || window.isMailmunch)) {
          let params = {};
          for (const [key, value] of searchParams) {
            params[key] = (key === 'purchase_value' ? Number(value) : value);
          }
          if (Object.keys(params).length && params?.purchase_value !== undefined) {
            window?.dataLayer?.push({ event: 'mm_Purchase', ...params });
            window.history.replaceState(null, null, window?.location?.href?.split("?")[0]);
          }
        }
      }
    }
  });

  const language = user?.data?.appLocale || getPartnerByHostName?.variables?.language;
  
  useTranslation(setTranslation, language);

  // App Routes
  const routes = useMemo(() => appRoutes({ platformFeatures: getPartnerByHostName?.platformFeatures }), [getPartnerByHostName?.platformFeatures])

  // Subscriber Count Query
  useQuery(DATA_FOR_SIDE_EFFECTS, {
    skip: !getStorageItem('user:token'),
    onCompleted: (data) => {
      const { me = {} } = data || {}
      const { subscribersCount, createdAt, email } = me || {}
      window.subscribersCount = subscribersCount
      if (hasPlatformFeature("customer_support", "chat")) {
        window.intercomSettings = {
          ...window.intercomSettings,
          num_subscribers: subscribersCount,
        }
      }

      // Wootric
      const has25Subscribers = subscribersCount >= 25
      const isMailmunch = window.isMailmunch || window.location.hostname.includes('mailmunch')

      if ( has25Subscribers && isMailmunch ) {
        let setupScript = document.createElement('script');
        setupScript.type = 'text/javascript';
        setupScript.id = 'wootric-settings';
        setupScript.async = true;
        setupScript.innerHTML = `
          wootric_no_surveyed_cookie = true;
          window.wootricSettings = {
            email: "${email}",
            created_at: "${Math.floor(new Date(createdAt)?.getTime()/1000)}",
            account_token: "${process.env.REACT_APP_WOOTRIC_ACCOUNT_TOKEN}"
          };
        `;
        if (document.getElementById('wootric-settings') == null) {
          document.body.appendChild(setupScript);
        }

        // Beacon
        let beacon = document.createElement('script');
        beacon.type = 'text/javascript';
        beacon.id = 'wootric-beacon';
        beacon.async = true;

        beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
        beacon.onload = function() {
          console.log('Please rate your experience with us!');
          window.wootric('run');
        };
        if (document.getElementById('wootric-beacon') == null) {
          document.body.appendChild(beacon)
        };
      }
    }
  });

  // useEffect: Check Search Params
  useEffect(() => {
    if (userToken) {
      if (userId) {
        const url = `${process.env.REACT_APP_RAILS}/valid_token?user_token=${userToken}&user_id=${userId}`
        axios.get(url).then(({ data }) => {
          if (data?.status === 200) {
            setStorageItem("user:token", data?.token)
          } else {
            ToastNotification({
              id: 1,
              icon: 'alert',
              color: 'danger',
              body: 'Link expired, request a new one or use account credentials.'
            })
          }
          removeParams(data?.status === 200, true, userImpersonated, agentToken)
        }).catch(() => {
          removeParams(false, true, userImpersonated, agentToken)
        })
      } else {
        setStorageItem("user:token", userToken)
        removeParams(true, false, userImpersonated, agentToken)
      }
    }
  }, [userToken, userId, userImpersonated, agentToken])

  // useEffect: Initialize Intercom
  useEffect(() => {
    if (hasPlatformFeature("customer_support", "chat")) {
      window.intercomSettings = {
        ...window.intercomSettings,
        app_id: process.env.REACT_APP_INTERCOM_ID,
        user_hash: user?.intercomUserHash,
        user_id: user?.id,
        email: user?.email,
        name: user?.name,
        created_at: new Date(user?.createdAt)?.getTime(), // Signup date as a Unix timestamp
        referral: user?.referral,
        num_sites: user?.siteCount,
        custom_launcher_selector: '#chat_with_us_link',
        hide_default_launcher: false,
        partner: window.partnerName
      };

      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/k32ojs70';
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();

      return () => {
        window?.Intercom('shutdown');
      }
    }
  }, [user?.id, user?.siteCount, window.partnerName])

  // useEffect: Initialize Pricing Chart 
  useEffect(() => {
    if (!window.location.pathname.includes("billing") && hasPlatformFeature('account', 'billing')) {
      const script = document.createElement("script");
      const div = document.createElement('div');
      div.id = 'mailmunch-pricing';
      script.id = 'mm-pricing';
      script.src = user?.pricingVersionUrl;
      script.async = true;
      document.body.appendChild(div);
      document.body.appendChild(script);

      window.react_app_rails = process.env.REACT_APP_RAILS

      return () => {
        document.body.removeChild(script);
        document.body.removeChild(div);
      }
    }

  }, [user?.id, user?.pricingVersionUrl]);

  // useEffect: Initialize Encharge API
  useEffect(() => {
    if (getPartnerByHostName?.variables && getPartnerByHostName?.variables?.enchargeWriteKey) {
      let enchargeApiScript = document.getElementById('encharge-api')
      if (!enchargeApiScript) {
        enchargeApiScript = document.createElement("script");
        enchargeApiScript.id = 'encharge-api'
        enchargeApiScript.innerHTML = `!function(){if(!window.EncTracking||!window.EncTracking.started){window.EncTracking=Object.assign({}, window.EncTracking, {queue:window.EncTracking&&window.EncTracking.queue?window.EncTracking.queue:[],track:function(t){this.queue.push({type:"track",props:t})},identify:function(t){this.queue.push({type:"identify",props:t})},started:!0});var t=window.EncTracking;t.writeKey="${getPartnerByHostName?.variables.enchargeWriteKey}",t.hasOptedIn=true,t.shouldGetConsent=true,t.hasOptedIn&&(t.shouldGetConsent=!1),t.optIn=function(){t.hasOptedIn=!0,t&&t.init&&t.init()},t.optOut=function(){t.hasOptedIn=!1,t&&t.setOptOut&&t.setOptOut(!0)};var n=function(t){var n=document.createElement("script");n.type="text/javascript",n.async=void 0===t||t,n.src="https://resources-app.encharge.io/encharge-tracking.min.js";var e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};"complete"===document.readyState?n():window.attachEvent?window.attachEvent("onload",n):window.addEventListener("load",n,!1)}}();`
        document.body.appendChild(enchargeApiScript);
      }

      return () => {
        enchargeApiScript && document.body.removeChild(enchargeApiScript)
      }
    }

  }, [getPartnerByHostName?.variables?.enchargeWriteKey]);

  // useEffect: Initialize EncTracking
  useEffect(() => {
    window.hj = window.hj || function () { (window.hj.q = window.hj.q || []).push(arguments) }
  }, [])

  if (!window.EncTracking) {
    window.EncTracking = { queue: [], track: function (e) { this.queue.push({ type: "track", props: e }) }, identify: function (e) { this.queue.push({ type: "identify", props: e }) } };
  }
  if (window.hj !== undefined) {
    window.hj('identify', (user?.id || null), {
      'user_id': (user?.id || null),
      'email': user?.email,
      'platform': user?.referral || 'mailmunch',
      'plan': user?.data?.pricing_version,
      'pricingPlan': user?.entitlements?.pricingPlan,
    })
  }

  // Create Variables & function
  const isShopifyWix = ['shopify', 'wix'].includes(user?.channelName?.toLowerCase())
  const loginPageUrl = getPartnerByHostName?.links?.login_page
  const loginPage = !getStorageItem('user:token') && (loginPageUrl || '/users/sign_in')

  const getRedirection = () => {
    const siteId = Number(window.siteId || user?.site?.id)
    return loginPage ? window.location.replace(loginPage) : ((!hasPlatformFeature('sites', 'switchable') || isShopifyWix) ? (siteId ? `/sites/${siteId}/campaigns` : '/sites/new') : (siteId ? '/sites' : '/sites/new'))
  }

  // Handle loading and Activation of Partner and User
  if (partnerLoading || fetchingUser) {
    return <SuspenseFallback />
  } else if (!getPartnerByHostName?.active) {
    return <NotFound />
  }

  // Private Routes Props
  const privateRoutesProps = {
    getRedirection,
    showVerificationScreen: (window.isMailmunch || window.isAppflows) && user?.isCreatedAfter && typeof user?.isVerified === 'boolean' && !user?.isVerified,
    userSiteId: user?.site?.id,
    isMaxSitesLimitExceeded: user?.siteCount >= user?.entitlements?.maxAllowedSites,
    isBillingGatewayBraintree: user?.entitlements?.billingGatewayName === 'braintree'
  }

  return (
    <IntlProvider locale={DEFAULT_TRANSLATION} messages={translation}>
      <BrowserRouter>
        <Suspense fallback={<SuspenseFallback />}>
          <Switch>
            <Route exact path="/" render={() => <Redirect to={getRedirection()} />} />
            {publicRoutes.map(({ path, component }, key) => <Route key={key} path={path} component={component} />)}
            {routes.map(({ path, component }, key) => <PrivateRoute key={key} path={path} component={component} {...privateRoutesProps} />)}
            <Route path={'*'} component={NotFound} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </IntlProvider>
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = !(getStorageItem('user:token') == null)
  const { getRedirection, showVerificationScreen, userSiteId, isMaxSitesLimitExceeded, isBillingGatewayBraintree } = rest

  const renderRoute = (props) => {
    if (!isLoggedIn) {
      return <Redirect to={{ pathname: getRedirection(), state: { from: props.location } }} />;
    }

    const { pathname } = props.location;
    const siteId = userSiteId || window.siteId;
    const isSubscribersRedirectRequired = window.isMailmunch && pathname.includes('subscribers') && siteId;
    const isNewSiteRedirectRequired = siteId && pathname === '/sites/new' && (isMaxSitesLimitExceeded || !isBillingGatewayBraintree);
    const isSitesRedirectRequired = (window.isAppflows && ['/sites', '/sites/'].includes(pathname)) ? siteId ? `/sites/${siteId}/campaigns` : '/sites/new' : false;

    if (showVerificationScreen && pathname !== '/verify-email') {
      return <Redirect to="/verify-email" />;
    } else if (isSubscribersRedirectRequired) {
      return <Redirect to={{ pathname: `/sites/${siteId}/contacts` }} />;
    } else if (isNewSiteRedirectRequired) {
      return <Redirect to={{ pathname: getRedirection(), ...(isBillingGatewayBraintree && { state: { maxSitesLimitExceeded: true } }) }} />;
    } else if (!!isSitesRedirectRequired) {
      return <Redirect to={{ pathname: isSitesRedirectRequired }} />;
    } else {
      return <Component isLoggedIn={isLoggedIn} {...props} />;
    }
  };

  return <Route {...rest} render={renderRoute} />;
}

export default Routes